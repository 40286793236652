import React from "react";
import {Table} from "semantic-ui-react";
import copy from "copy-to-clipboard";
import usersStore from "../UsersStore";
import {UserDto} from "../../Entities/Dtos";
import utils from "../../Services/Utils";

interface IUserRowProps {
    user: UserDto;
    number: number;
}

function UserRow(props: IUserRowProps) {
    const {user, number} = props;
    return (
        <Table.Row>
            <Table.Cell>{number}</Table.Cell>
            <Table.Cell selectable onClick={() => usersStore.generateToken(user.id)} style={{cursor: 'pointer'}}>
                {user.id}
            </Table.Cell>
            <Table.Cell>{user.login}</Table.Cell>
            <Table.Cell>{user.productsCount}</Table.Cell>
            <Table.Cell style={{cursor: 'pointer'}} onClick={() => copy(user.skladToken)}
                        title={'скопировать'}>{user.skladToken}</Table.Cell>
            {user.subscription &&
                <>
                    <Table.Cell>{user.subscription.tariffName}{user.subscription.isTrial && ' [Триал]'}{user.useOrdersIntegration && ' [Заказы]'}</Table.Cell>
                    <Table.Cell>{user.subscription.expiredOn ? utils.getLocalizedDateString(user.subscription.expiredOn) : '-'}</Table.Cell>
                </>
            }
        </Table.Row>
    )
}

export default UserRow;
